import { UPLOAD_FILE_SUCCESS, UPDATE_FILE_SUCCESS, DELETE_FILE_SUCCESS, LOAD_FILES_LIST_SUCCESS } from '../actions/file';
import { ADD_ITEM_SUCCESS } from '../actions/item';
import { LOAD_ORDER_SUCCESS } from '../actions/order';
import { EMPTY_CART } from '../actions/shop';
import { toTimestamp } from '../utils';
import { UPDATE_PRODUCT_ITEMS_SUCCESS } from '../actions/shop';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ITEM_SUCCESS:
      return Object.assign({}, state, (action.payload.item.files || []).reduce((o, f) => {o[f.file_id] = f; return o;}, {}), action.payload.artworks.reduce((o, a) => { o[a.artwork_file_id] = a.file; return o; }, {}));
    case UPLOAD_FILE_SUCCESS:
      action.payload.data.date_created = toTimestamp(action.payload.data.date_created);
      return Object.assign({}, state, {[action.payload.id]: action.payload.data});
    case UPDATE_FILE_SUCCESS:
      action.payload.data.date_created = toTimestamp(action.payload.data.date_created);
      return Object.assign({}, state, {[action.payload.id]: Object.assign({}, state[action.payload.id], action.payload.data)});
    case DELETE_FILE_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case LOAD_FILES_LIST_SUCCESS:
      // We want to keep the old files as well
      return Object.assign({}, state, {...((action.payload.files || []).reduce((o, f) => {o[f.file_id] = f; return o;}, {})) });
    case LOAD_ORDER_SUCCESS:
      return { ...state, ...(action.payload.order.items.reduce((o, i) => ({ ...o, ...((i.files || []).reduce((o, f) => ({ ...o, [f.file_id]: f }), {})) }), {})) };
    case EMPTY_CART:
      return Object.values(state).filter(f => 'GUEST' !== f.parent_id).reduce((o, f) => ({ ...o, [f.file_id]: f }), {});
    case UPDATE_PRODUCT_ITEMS_SUCCESS:
      return { ...state, ...action.payload.artworks };
  }
  return state;
};

export default reducer;
