import { AlertIcon, Button, colors, H2, LabeledRadioInButton, Popup } from '@commonsku/styles'
import React, { useState } from 'react'

function PsstPopup({ onClose, selectedAddress, psstAddress, formatAddress, onChoosePsstAddress }) {
  const [selectedId, setSelectedId] = useState(psstAddress.address_id)
  const psstDecoratorName = psstAddress.address_name;
  const formattedPsstAddress = formatAddress(psstAddress);
  const selectedDecoratorName = selectedAddress.address_name;
  const formattedSelectedAddress = formatAddress(selectedAddress);

  const handleChoosePsstAddress = () => {
    if (selectedId === psstAddress.address_id) {
      onChoosePsstAddress(formattedPsstAddress);
    }
    onClose();
  }
  return (
    <Popup style={{ width: 701, borderRadius: 10 }} height="auto" noHeader popupContentStyle={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <H2 style={{
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '40px',
          fontFamily: 'skufont-regular',
          marginBottom: 0,
        }}>Confirm PSST Address</H2>
        <div style={{ display: 'flex', borderRadius: 5, gap: 16, padding: 16, color: colors.secondary4.darkest, backgroundColor: colors.secondary4.lightest }}>
          <AlertIcon style={{ minWidth: 24 }} color={colors.secondary4.darkest} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div>For PSST to work, you must use an address from SanMar’s list. We selected an address that matches your selection......
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ flex: 1 }}>
            <div style={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
            }}>Use address from SanMar (recommended)</div>
            <LabeledRadioInButton labelStyle={{ width: '100%', justifyContent: 'start', borderRadius: 16, padding: 8, margin: 0 }} checked={selectedId === psstAddress.address_id} onClick={() => setSelectedId(psstAddress.address_id)}>
              <div style={{ display: 'flex', flexDirection: 'column', color: colors.neutrals.darkest, fontFamily: 'skufont-regular' }}>
                <div style={{ fontWeight: 700, fontSize: 16, lineHeight: '24px' }}>{psstDecoratorName}</div>
                <div style={{ fontWeight: 400, fontSize: 14, lineHeight: '24px' }}>{formattedPsstAddress}</div>
              </div>
            </LabeledRadioInButton>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
            }}>Use your address (may exclude PSST benefits)</div>
            <LabeledRadioInButton labelStyle={{ width: '100%', justifyContent: 'start', borderRadius: 16, padding: 8, margin: 0 }} checked={selectedId === selectedAddress.address_id} onClick={() => setSelectedId(selectedAddress.address_id)}>
              <div style={{ display: 'flex', flexDirection: 'column', color: colors.neutrals.darkest, fontFamily: 'skufont-regular' }}>
                <div style={{ fontWeight: 700, fontSize: 16, lineHeight: '24px' }}>{selectedDecoratorName}</div>
                <div style={{ fontWeight: 400, fontSize: 14, lineHeight: '24px' }}>{formattedSelectedAddress}</div>
              </div>
            </LabeledRadioInButton>
          </div>
        </div>

      </div>
      <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'end ', flexGrow: 1, justifyContent: 'end', gap: 8 }}>
        <Button variant="secondary" size='medium' onClick={onClose}>Cancel</Button>
        <Button size='medium' onClick={handleChoosePsstAddress}>Confirm</Button>
      </div>
    </Popup>
  )
}

export default PsstPopup